import React, {ReactElement} from "react";
import {Auth} from "@firebase/auth";
import {Box} from "@mui/material";
import {PD_SM} from "./dimens";
import {Contact, User, UserDisplayName, UserProfilePhoto} from "./types";
import {BaseFragment, BaseFragmentState} from "./BaseFragment";
import {StyledListItem} from "./Components";

export type BaseContactsFragmentProps = {
  auth: Auth,
}

export type BaseContactsFragmentState = BaseFragmentState & {
  items?: ContactItem[],
}

export type ContactItem = {
  contact: Contact,
  user: User,
}

export abstract class BaseContactsFragment<P extends BaseContactsFragmentProps = BaseContactsFragmentProps, S extends BaseContactsFragmentState = BaseContactsFragmentState> extends BaseFragment<P, S> {

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      items: [],
    };
  }

  protected renderItems(items?: ContactItem[]): ReactElement | null {
    return <Box style={{
      width: "100%",
      maxWidth: 720,
      margin: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: PD_SM,
      position: "relative",
    }}>
      {items?.length > 0 ? items.map(item => <StyledListItem
          title={UserDisplayName(item.user)}
          img={UserProfilePhoto(item.user)}
          text={item.user.email}
          object={item.contact}
          accessory={this.renderItemAccessory(item)}/>)
        : this.renderEmpty()}
    </Box>;
  }

  protected renderItemAccessory(item: ContactItem): React.ReactElement | null {
    return null;
  }
}
