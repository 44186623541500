import {BaseFragment} from "./BaseFragment";
import {Box} from "@mui/material";
import {StyledPdfView} from "./Components";
import {black} from "./colors";

export interface BookFragmentListener {
  onPageChanged(pdf: string, page: number): void,
}

export type BookFragmentProps = {
  pdf: string,
  page: number,
  listener: BookFragmentListener,
}

export class BookFragment extends BaseFragment<BookFragmentProps> {

  render(): JSX.Element {
    return <Box style={{width: "100%", height: "100%"}}>
      <StyledPdfView
        pdf={this.props.pdf}
        page={this.props.page}
        onPageChanged={(page: number) => this.props.listener.onPageChanged(this.props.pdf, page)}/>
    </Box>
  }
}
