import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {PD_SM} from "./dimens";
import {MailOutlined} from "@mui/icons-material";
import {Contact, EmptyConfig, Invitations, Invite, UserCache} from "./types";
import {getDatabase, push, ref as dbRef, remove, set} from "@firebase/database";
import {
  BaseContactsFragment,
  BaseContactsFragmentProps,
  BaseContactsFragmentState,
  ContactItem
} from "./BaseContactsFragment";
import {md5} from "./md5";
import App from "./App";

export type InvitationsFragmentProps = BaseContactsFragmentProps & {}

type InvitationsFragmentState = BaseContactsFragmentState & {}

export class InvitationsFragment extends BaseContactsFragment<InvitationsFragmentProps, InvitationsFragmentState> {

  private readonly invitations = Invitations.getInstance();

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: MailOutlined,
      title: "No invitations found",
      text: "When someone sends you an invitation to Playtime, it will show up here.",
    };
  }

  protected async fetchOnMount(): Promise<void> {
    this.setState({
      items: await Promise.all(this.invitations.getReceived().map(contact => {
        async function getContactItem(contact: Contact) {
          const user = await UserCache.getInstance().getUser(contact.uid);
          return {
            contact: contact,
            user: user,
          } as ContactItem;
        }

        return getContactItem(contact);
      }))
    })
  }

  protected renderContainerContent(): React.ReactElement | null {
    let items = this.state.items;
    return <>
      {this.renderItems(items)}
    </>;
  }

  protected renderItemAccessory(item: ContactItem): React.ReactElement | null {
    return <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
      <Button
        onMouseDown={event => event.stopPropagation()}
        onClick={() => {
          const currentUser = this.props.auth.currentUser;

          async function accept() {
            const db = getDatabase();
            // Add sender as connection.
            const newConnectionRef = push(dbRef(db, "connections/" + currentUser.uid));
            await set(newConnectionRef, item.contact);
            // Remove invite I received.
            const receivedInviteRef = dbRef(db, "invitations/received/" + md5(currentUser.email) + "/" + item.contact.id);
            await remove(receivedInviteRef);
            // Send invite back to sender from me.
            const invite = {
              uid: currentUser.uid,
              email: currentUser.email, // "from"
              to: item.contact.email,
              created: Date.now(),
            } as Invite;
            const inviteRef = push(dbRef(db, "invitations/received/" + md5(invite.to)));
            await set(inviteRef, invite);
          }

          accept()
            .then(() => {
              App.CONTEXT.showToast("Accepted.");
              this.reload();
            })
            .catch(reason => {
              console.log(reason);
              App.CONTEXT.showToast("Oops, something went wrong. Please try again later.")
            });
        }}
      ><Typography>Accept</Typography></Button>
      <Button
        onMouseDown={event => event.stopPropagation()}
        onClick={() => {
          const db = getDatabase();
          const newConnectionRef = dbRef(db, "invitations/received/" + md5(this.props.auth.currentUser.email) + "/" + item.contact.id);
          remove(newConnectionRef)
            .then((value) => {
              App.CONTEXT.showToast("Rejected.");
              this.reload();
            })
            .catch(reason => App.CONTEXT.showToast("Oops, something went wrong. Please try again later."));
        }}
      ><Typography>Reject</Typography></Button>
    </Box>;
  }
}
