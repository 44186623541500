export const FEATURED_DISPLAY_NAMES = {
  "beginning_readers": "Beginning readers",
  "learning_to_read": "Learning to read",
  "reading_independently": "Reading independently",
  "proficient_readers": "Proficient readers",
  "advanced_readers": "Advanced readers",
};

export const AVATARS = [
  "ant",
  "bat",
  "beetle",
  "butterfly",
  "camel",
  "cat",
  "chipmunk",
  "deer",
  "dog",
  "dolphin",
  "elephant",
  "fish",
  "giraffe",
  "hedgehog",
  "hippo",
  "kangaroo",
  "monkey",
  "octopus",
  "owl",
  "parrot",
  "peacock",
  "rabbit",
  "rhino",
  "snail",
  "tiger",
  "turtle",
  "whale",
  "zebra",
];

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAcFjWYhLzjwpxYOSLPaV8xDDGWdBkZSJE",
  authDomain: "fyneplaykids-playtime.firebaseapp.com",
  databaseURL: "https://fyneplaykids-playtime-default-rtdb.firebaseio.com",
  projectId: "fyneplaykids-playtime",
  storageBucket: "fyneplaykids-playtime.appspot.com",
  messagingSenderId: "1022196056456",
  appId: "1:1022196056456:web:de55b01cedb31bfb193a3d",
  measurementId: "G-VY4N204NWP"
};

export const TOKEN_URL = "https://us-central1-fyneplaykids-playtime-twlo.cloudfunctions.net/token?identity=";
