import React from "react";
import {Auth} from "@firebase/auth";
import {Box, MenuItem, Select, TextField} from "@mui/material";
import {DW_XL, PD_MD, PD_SM, SZ_JUMBO} from "./dimens";
import {SearchOutlined} from "@mui/icons-material";
import {BASE_STATE} from "./BaseFragment";
import {
  BaseFullscreenDialogFragment,
  BaseFullscreenDialogFragmentProps,
  BaseFullscreenDialogFragmentState
} from "./BaseFullscreenDialogFragment";
import {FEATURED_DISPLAY_NAMES} from "../consts";
import {Book, Books, EmptyConfig, Item} from "./types";
import {StyledBook} from "./Components";

export type SearchFragmentProps = BaseFullscreenDialogFragmentProps & {
  auth: Auth,
  featured?: string,
}

type SearchFragmentState = BaseFullscreenDialogFragmentState & {
  featured: string,
  search: string,
  results?: Book[],
  items?: Item[],
}

export class SearchFragment extends BaseFullscreenDialogFragment<SearchFragmentProps, SearchFragmentState> {

  private readonly books = Books.getInstance();

  constructor(props: SearchFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
      featured: props.featured || "",
      search: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSearch();
  }

  componentDidUpdate(prevProps: Readonly<SearchFragmentProps>, prevState: Readonly<SearchFragmentState>, snapshot?: any) {
    if (prevState.search !== this.state.search) {
      this.setState({
        ready: false,
      });
      this.doSearch(this.state.search)
        .then(books => {
          this.setState({
            ready: true,
            results: books,
          });
        });
    }
    if (prevState.featured !== this.state.featured) {
      this.setState({
        ready: true,
        search: "",
        results: [],
      })
    }
    if (prevState.results !== this.state.results) {
      this.updateSearch();
    }
  }

  private async doSearch(search?: string): Promise<Book[]> {
    if (search.length > 0) {
      const books = this.books.getBooks();
      return books.filter(book => {
        return (!this.state.featured.length || this.state.featured === book.featured) && book.title.toLowerCase().includes(search.toLowerCase())
      });
    } else {
      return [];
    }
  }

  private updateSearch() {
    if (this.state.search.length > 0) {
      this.setState({
        items: this.state.results.map(book => {
          return {
            object: book,
            text: book.title,
            img: book.coverImage,
          } as Item;
        })
      });
    } else if (this.state.featured.length > 0) {
      const books = this.books.getBooks();
      this.setState({
        items: books.filter(book => book.featured == this.state.featured).map(book => {
          return {
            object: book,
            text: book.title,
            img: book.coverImage,
          } as Item;
        })
      });
    } else {
      this.setState({
        items: [],
      });
    }
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: SearchOutlined,
      title: "Search a book",
      text: "Type in the search box to look up a book title.",
    };
  }

  protected renderFullscreenDialogContent(): React.ReactElement | null {
    return <>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: PD_SM,
        margin: "auto",
        flexGrow: 1,
        maxWidth: DW_XL,
      }}>
        <Box style={{display: "flex", gap: PD_MD}}>
          <Select
            variant="outlined"
            style={{width: SZ_JUMBO, flexShrink: 0}}
            value={this.state.featured}
            onChange={event => this.setState({featured: event.target.value})}
            displayEmpty
          >
            {Object.entries({"": "All", ...FEATURED_DISPLAY_NAMES}).map(([key, value]) => <MenuItem value={key}>
              {value}
            </MenuItem>)}
          </Select>
          <TextField
            value={this.state.search}
            autoFocus size="medium"
            placeholder="Search"
            style={{flexGrow: 1}}
            onChange={event => this.setState({search: event.target.value.trim()})}/>
        </Box>
        {this.state.items?.length ? <Box style={{flexGrow: 1, margin: "auto", overflowY: "scroll"}}>
            <Box style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexWrap: "wrap",
            }}>
              {this.state.items.map(item => <StyledBook item={item}/>)}
            </Box>
          </Box>
          : null
        }
      </Box>
      {!this.state.items?.length ? this.renderEmpty() : null}
    </>;
  }
}
