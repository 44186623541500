import {Auth} from "@firebase/auth";
import React from "react";
import {Book, Books, BookSyncPayload, Item, Layout, Section, SyncBook, SyncType} from "./types";
import {Box, Button, Typography} from "@mui/material";
import {PD_LG, PD_MD, PD_XLG} from "./dimens";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import {FEATURED_DISPLAY_NAMES} from "../consts";
import {StyledBook} from "./Components";
import App, {DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN} from "./App";
import {SearchFragment} from "./SearchFragment";

export type BooksFragmentProps = {
  auth: Auth,
}

type BooksFragmentState = BaseFragmentState & {}

// const LAYOUT: Layout = {
//   sections: [
//     {
//       title: "Featured",
//       items: [
//         {
//           title: "Hindi",
//           text: "Learn the Hindi alphabet",
//           rating: 4,
//           icon: "",
//           img: "",
//         },
//         {
//           title: "Punjabi",
//           text: "Learn the Punjabi alphabet",
//           rating: 4,
//           icon: "",
//           img: "",
//         },
//         {
//           title: "Urdu",
//           text: "Learn the Urdu alphabet",
//           rating: 4,
//           icon: "",
//           img: "",
//         },
//       ],
//     },
//   ],
// };

export class BooksFragment extends BaseFragment<BooksFragmentProps, BooksFragmentState> {

  private readonly books = Books.getInstance();

  private layout: Layout;

  constructor(props: BooksFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    await this.books.loadBooks();
    const books = this.books.getBooks();
    const featuredMap = new Map<string, Book[]>();
    books.forEach(book => {
      let bookList = featuredMap.get(book.featured);
      if (!bookList) {
        bookList = [];
        featuredMap.set(book.featured, bookList);
      }
      bookList.push(book);
    });
    const sections: Section[] = [];
    featuredMap.forEach((value: Book[], key: string) => {
      sections.push({
        title: FEATURED_DISPLAY_NAMES[key] || "Section",
        object: key,
        items: value.map(book => {
          return {
            object: book,
            text: book.title,
            img: book.coverImage,
          } as Item;
        }),
      });
    });
    this.layout = {
      sections: sections,
    };
    return Promise.resolve();
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      paddingLeft: PD_XLG,
      paddingRight: PD_XLG,
      paddingTop: PD_LG,
      paddingBottom: PD_LG,
      // background: "#f0f8ff",
    }}>
      {this.layout?.sections.map(section => <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
        <Box style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center"}}>
          <Typography variant="h5">{section.title}</Typography>
          <div style={{flexGrow: 1}}/>
          <Button variant="text"
                  onClick={() => {
                    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN }, () =>
                      <SearchFragment
                        title="Search" featured={section.object as string} auth={this.props.auth}/>);
                  }}>
            <Typography style={{fontSize: "125%", fontWeight: "bold"}}>SEE ALL</Typography>
          </Button>
        </Box>
        <Box className="hidescroll" style={{
          display: "flex",
          marginLeft: -72,
          marginRight: -72,
          paddingLeft: 48,
          paddingRight: 48,
          flexDirection: "row",
          overflowX: "scroll",
        }}>
          {section.items.slice(0, 12).map(item => <StyledBook item={item} onClick={() => {
            App.CONTEXT.onSyncChanged(SyncBook(this.props.auth, (item.object as Book).pdf, 1));
          }}/>)}
        </Box>
      </Box>)}
    </Box>;
  }
}
