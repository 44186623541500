import {getDownloadURL, getStorage, ref as storageRef} from "@firebase/storage";
import {SvgIcon} from "@mui/material";
import {get, getDatabase, off, onValue, push, ref as dbRef, remove, set} from "@firebase/database";
import {md5} from "./md5";
import {Auth} from "@firebase/auth";
import defaultImage from "../res/images/default_user.png";
import {
  connect,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  Logger,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room
} from "twilio-video";
import {TOKEN_URL} from "../consts";

export type User = {
  uid: string,
  email?: string,
  firstname?: string,
  lastname?: string,
  avatar?: string,
  profilePhotoFullPath?: string,
  profilePhotoUrl?: string,
}

export function UserDisplayName(user?: User) {
  let displayName = user?.firstname?.length > 0 ? user.firstname : "";
  displayName += user?.lastname?.length > 0 ? ((displayName.length > 0 ? " " : "") + user?.lastname) : "";
  return displayName;
}

export function UserProfilePhoto(user?: User) {
  return (user?.avatar ? "/assets/avatars/" + user.avatar + ".png" : null) || user?.profilePhotoUrl || defaultImage;
}

export function UnknownUser(uid: string): User {
  return {
    uid: uid,
    firstname: "[ Unknown ]",
    lastname: "",
  } as User;
}

export class UserCache {

  private static readonly instance = new UserCache();

  private cache: Map<string, User> = new Map<string, User>();

  static getInstance(): UserCache {
    return this.instance;
  }

  setUser(uid: string, user: User): void {
    this.cache.set(uid, user);
  }

  async getUser(uid: string): Promise<User> {
    let cached = this.cache.get(uid);
    if (cached) {
      return Promise.resolve(cached);
    }
    const db = getDatabase();
    const userRef = dbRef(db, "users/" + uid);
    const result = await get(userRef);
    if (result.exists()) {
      let profilePhotoUrl = null;
      let user = result.val();
      if (user.profilePhotoFullPath?.length > 0) {
        const storage = getStorage();
        profilePhotoUrl = await getDownloadURL(storageRef(storage, "users/profile-photos/" + uid));
      }
      cached = {
        ...user,
        profilePhotoUrl: profilePhotoUrl,
        uid: uid,
      };
      this.cache.set(uid, cached);
      return Promise.resolve(cached);
    }
    return Promise.resolve(UnknownUser(uid));
  }
}

export type Section = {
  title?: string,
  object?: any,
  items: Item[],
}

export type Item = {
  object?: any,
  title: string,
  text: string,
  rating?: number,
  icon?: string,
  img?: string,
}

export type Layout = {
  sections: Section[],
}

export type Book = {
  source: string;
  id: string;
  title: string;
  description: string;
  language: string; //Nullable
  ageFrom: number;
  ageTo: number;
  authors: string;
  featured: string;
  tags: string[]; // Nullable
  publisher: string;

  coverImage?: string,
  pdf?: string,
}

export type Invite = {
  uid: string,
  email: string,
  to: string,
  created: number,
}

export type Contact = Invite & {
  id: string,
}

export enum PhonecallType {
  CREATE = "create",
  CONNECT = "connect",
  START = "start",
  END = "end",
}

export type Phonecall = {
  id?: string;
  room: string,
  type: PhonecallType,
  from: string,
  to: string,
  time: number,
  created: number,
  started?: number,
  ended?: number,
  payload?: any,
}

export enum SyncType {
  NONE = "none",
  BOOK = "book",
}

export function SyncNone(auth: Auth): Sync {
  return {
    id: md5("" + Date.now(), auth.currentUser?.uid),
    type: SyncType.NONE,
    from: auth.currentUser.uid,
    time: Date.now(),
  };
}

export function SyncBook(auth: Auth, pdf: string, page: number): Sync {
  return {
    id: md5("" + Date.now(), auth.currentUser?.uid),
    type: SyncType.BOOK,
    from: auth.currentUser.uid,
    time: Date.now(),
    payload: {
      pdf: pdf,
      page: page,
    } as BookSyncPayload,
  };
}

export type Sync = {
  id: string,
  type: SyncType,
  from: string,
  time: number,
  payload?: any,
}

export type BookSyncPayload = {
  pdf: string,
  page: number,
}

export enum PhonecallEndReason {
  NO_ANSWER,
  HANG_UP,
  TIME_OUT,
  BUSY,
  NAVIGATED_AWAY,
  NO_PERMISSIONS,
}

export type PhonecallEndPayload = {
  reason: PhonecallEndReason,
}

export const PHONECALL_END_REASON_TEXT_FROM_ME = new Map<PhonecallEndReason, string>([
    [
      PhonecallEndReason.NO_ANSWER, "Did not answer"
    ],
    [
      PhonecallEndReason.HANG_UP, "Call ended"
    ],
    [
      PhonecallEndReason.TIME_OUT, "Lost connection"
    ],
    [
      PhonecallEndReason.BUSY, "On another call",
    ],
    [
      PhonecallEndReason.NAVIGATED_AWAY, "Left Playtime",
    ],
    [
      PhonecallEndReason.NO_PERMISSIONS, "Permissions not granted",
    ],
  ]
)

export const PHONECALL_END_REASON_TEXT_FROM_OTHER = new Map<PhonecallEndReason, string>([
    [
      PhonecallEndReason.NO_ANSWER, "You missed the call"
    ],
    [
      PhonecallEndReason.HANG_UP, "Call ended"
    ],
    [
      PhonecallEndReason.TIME_OUT, "Lost connection"
    ],
    [
      PhonecallEndReason.BUSY, "On another call",
    ],
    [
      PhonecallEndReason.NAVIGATED_AWAY, "Left Playtime",
    ],
    [
      PhonecallEndReason.NO_PERMISSIONS, "Permissions not granted",
    ],
  ]
)

export class Books {

  private static instance = new Books();

  static getInstance(): Books {
    return this.instance;
  }

  private books: Book[] = [];

  private readonly booksCache: Map<string, Book> = new Map<string, Book>();

  private constructor() {
  }

  async loadBooks(): Promise<void> {
    // this.books = [];
    const db = getDatabase();
    const booksRef = dbRef(db, "books");
    const result = await get(booksRef);
    const books: Book[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        books.push({
          ...value,
        } as Book);

      }
      this.books = books;
    }
  }

  getBooks(): Book[] {
    return this.books;
  }
}

export class Connections {

  private static instance = new Connections();

  static getInstance(): Connections {
    return this.instance;
  }

  private connections: Contact[] = [];

  async loadConnections(auth: Auth): Promise<void> {
    const db = getDatabase();
    const connectionsRef = dbRef(db, "connections/" + auth.currentUser.uid);
    const result = await get(connectionsRef);
    const connections: Contact[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        connections.push({
          ...value,
          id: key,
        } as Contact);

      }
      this.connections = connections;
    }
    return Promise.resolve();
  }

  getConnections(): Contact[] {
    return this.connections;
  }

  async deleteConnection(auth: Auth, connection: Contact): Promise<void> {
    const db = getDatabase();
    const connectionRef = dbRef(db, "connections/" + auth.currentUser.uid + "/" + connection.id);
    return remove(connectionRef);
  }
}

export class Invitations {

  private static instance = new Invitations();

  static getInstance(): Invitations {
    return this.instance;
  }

  private received: Contact[] = [];
  private sent: Contact[] = [];

  async loadInvitations(auth: Auth): Promise<void> {
    this.received = await this.load(auth, "invitations/received/");
    this.sent = await this.load(auth, "invitations/sent/");
    return Promise.resolve();
  }

  private async load(auth: Auth, path: string): Promise<Contact[]> {
    const db = getDatabase();
    const invitationsRef = dbRef(db, path + md5(auth.currentUser.email));
    const result = await get(invitationsRef);
    const invitations: Contact[] = [];
    if (result.exists()) {
      const val = result.val();
      for (const key in val) {
        const invitation = val[key];
        invitations.push({
          ...invitation,
          id: key,
        } as Contact);
      }
      return invitations;
    }
    return [];
  }

  getReceived(): Contact[] {
    return this.received;
  }

  getSent(): Contact[] {
    return this.sent;
  }

  async sendInvite(auth: Auth, email: string): Promise<void> {
    const db = getDatabase();
    const invite = {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email, // "from"
      to: email,
      created: Date.now(),
    } as Invite;
    const sentInviteRef = push(dbRef(db, "invitations/sent/" + md5(invite.email)));
    const receivedInviteRef = push(dbRef(db, "invitations/received/" + md5(invite.to)));
    return set(sentInviteRef, invite)
      .then(() => set(receivedInviteRef, invite));
  }
}

const IS_MOBILE = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

const DEFAULT_CONNECT_OPTIONS = {
  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  // Capture 720p video @ 24 fps.
  video: {height: 720, frameRate: 24, width: 720}
};

export interface RoomListener {

  onConnected(room: Room);

  onAttachTrack(room: Room, track: LocalTrack | RemoteTrack, participant: LocalParticipant | RemoteParticipant);

  onDetachTrack(room: Room, track: LocalTrack | RemoteTrack, participant: LocalParticipant | RemoteParticipant);

  onDisconnected(room: Room);
}

export interface PhonecallsListener {
  onPhonecallChanged(currentPhonecall: Phonecall | null);

  onSyncChanged(sync: Sync);
}

export class Phonecalls {

  private static instance = new Phonecalls();

  private currentPhonecall: Phonecall;
  private currenPhonecallTimeoutId: any;
  private phonecallsListener: PhonecallsListener;
  private roomListener: RoomListener;

  static getInstance(): Phonecalls {
    return this.instance;
  }

  subscribe(auth: Auth, phonecallsListener: PhonecallsListener): void {
    if (this.phonecallsListener) {
      throw Error;
    }
    this.phonecallsListener = phonecallsListener;
    const db = getDatabase();
    const phonecallsRef = dbRef(db, "phonecalls/" + auth.currentUser.uid);
    onValue(phonecallsRef, snapshot => {
      if (snapshot.exists()) {
        const phonecalls: Phonecall[] = [];
        const val = snapshot.val();
        for (const key in val) {
          const phonecall = val[key];
          phonecalls.push({
            ...phonecall,
            id: key,
          } as Phonecall);
        }
        phonecalls.sort((a, b) => a.time - b.time);
        for (const phonecall of phonecalls) {
          if (this.currentPhonecall && this.currentPhonecall.type !== PhonecallType.END && this.currentPhonecall.room !== phonecall.room) {
            this.endOtherPhonecall(auth, phonecall, PhonecallEndReason.BUSY);
          } else {
            clearTimeout(this.currenPhonecallTimeoutId);
            this.currenPhonecallTimeoutId = null;
            this.currentPhonecall = phonecall;
            this.phonecallsListener.onPhonecallChanged(this.currentPhonecall)
            if (this.currentPhonecall.type === PhonecallType.END) {
              this.room?.disconnect();
              this.room = null;
              switch ((this.currentPhonecall.payload as PhonecallEndPayload)?.reason) {
                case PhonecallEndReason.HANG_UP:
                  setTimeout(() => {
                    this.clearCurrentPhonecall();
                  }, 1000);
                  break;
                case PhonecallEndReason.NO_ANSWER:
                case PhonecallEndReason.TIME_OUT:
                case PhonecallEndReason.BUSY:
                case PhonecallEndReason.NAVIGATED_AWAY:
                case PhonecallEndReason.NO_PERMISSIONS:
                  // Nothing. UI should present a button to clear out current phonecall.
                  break;
                default:
                  this.clearCurrentPhonecall();
                  break;
              }
            }
          }
          remove(dbRef(db, "phonecalls/" + auth.currentUser.uid + "/" + phonecall.id));
        }
      }
    });
  }

  clearCurrentPhonecall() {
    this.currentPhonecall = null;
    this.phonecallsListener?.onPhonecallChanged(null);
    this.phonecallsListener?.onSyncChanged(null);
  }

  // CALLER ONLY
  async createPhonecall(auth: Auth, to: string) {
    if (this.currentPhonecall) {
      throw Error;
    }
    const db = getDatabase();
    const mePhonecallRef = push(dbRef(db, "phonecalls/" + auth.currentUser.uid));
    const createTime = Date.now();
    const createPhonecall = {
      room: md5("" + Date.now(), auth.currentUser.uid, to),
      type: PhonecallType.CREATE,
      from: auth.currentUser.uid,
      to: to,
      time: createTime,
      created: createTime,
    } as Phonecall;
    await set(mePhonecallRef, createPhonecall);
  }

  // CALLER ONLY
  async ringCurrentPhonecall(auth: Auth, roomListener: RoomListener) {
    if (!this.currentPhonecall) {
      throw Error;
    }

    await this.setupAndJoinRoom(auth, roomListener);

    const db = getDatabase();
    const otherPhonecallRef = push(dbRef(db, "phonecalls/" + this.currentPhonecall.to));
    await set(otherPhonecallRef, this.currentPhonecall);
    this.currenPhonecallTimeoutId = setTimeout(() => this.endCurrentPhonecall(auth, PhonecallEndReason.NO_ANSWER), 30000);
  }

  // CALLEE ONLY
  async connectAndStartCurrentPhonecall(auth: Auth, roomListener: RoomListener) {
    if (!this.currentPhonecall) {
      throw Error;
    }

    const db = getDatabase();
    const mePhonecallRef = push(dbRef(db, "phonecalls/" + auth.currentUser.uid));
    const otherPhonecallRef = push(dbRef(db, "phonecalls/" + Phonecalls.getOtherUid(auth, this.currentPhonecall)));
    const connectTime = Date.now();
    const connectPhonecall = {
      ...this.currentPhonecall,
      type: PhonecallType.CONNECT,
      time: connectTime,
      started: connectTime,
    } as Phonecall;
    await set(mePhonecallRef, connectPhonecall);
    await set(otherPhonecallRef, connectPhonecall);

    await this.setupAndJoinRoom(auth, roomListener);

    const startTime = Date.now();
    const startPhonecall = {
      ...this.currentPhonecall,
      type: PhonecallType.START,
      time: startTime,
      started: startTime,
    } as Phonecall;
    await set(mePhonecallRef, startPhonecall);
    await set(otherPhonecallRef, startPhonecall);
  }

  private async setupAndJoinRoom(auth: Auth, roomListener: RoomListener) {
    const response = await fetch(TOKEN_URL + auth.currentUser.uid);
    const token = await response.text();
    const deviceIds = {
      audio: IS_MOBILE ? null : localStorage.getItem('audioDeviceId'),
      video: IS_MOBILE ? null : localStorage.getItem('videoDeviceId')
    };
    const connectOptions: any = {
      ...DEFAULT_CONNECT_OPTIONS,
    }
    connectOptions.audio = {deviceId: {exact: deviceIds.audio}};
    connectOptions.name = this.currentPhonecall.room;
    connectOptions.video.deviceId = {exact: deviceIds.video};
    await this.joinRoom(token, connectOptions, roomListener);

    const db = getDatabase();
    const syncRef = dbRef(db, "sync/" + this.currentPhonecall.room);
    onValue(syncRef, snapshot => {
      if (snapshot.exists()) {
        const val = snapshot.val() as Sync;
        this.phonecallsListener.onSyncChanged(val);
      }
    });
  }

// CALLER OR CALLEE
  async endCurrentPhonecall(auth: Auth, reason: PhonecallEndReason) {
    if (!this.currentPhonecall) {
      throw Error;
    }

    const db = getDatabase();
    const mePhonecallRef = push(dbRef(db, "phonecalls/" + auth.currentUser.uid));
    const otherPhonecallRef = push(dbRef(db, "phonecalls/" + Phonecalls.getOtherUid(auth, this.currentPhonecall)));
    const endTime = Date.now();
    const endPhonecall = {
      ...this.currentPhonecall,
      type: PhonecallType.END,
      time: endTime,
      ended: endTime,
      payload: {
        reason: reason,
      } as PhonecallEndPayload,
    } as Phonecall;
    await set(mePhonecallRef, endPhonecall);
    await set(otherPhonecallRef, endPhonecall);

    const syncRef = dbRef(db, "sync/" + this.currentPhonecall.room);
    off(syncRef);
    await remove(syncRef);
  }

  async setCurrentPhonecallSync(auth: Auth, sync: Sync | null) {
    if (!this.currentPhonecall) {
      return;
    }

    const db = getDatabase();
    const syncRef = dbRef(db, "sync/" + this.currentPhonecall.room);
    if (sync) {
      await set(syncRef, sync);
    } else {
      await remove(syncRef);
    }
  }

  private async endOtherPhonecall(auth: Auth, otherPhonecall: Phonecall, reason: PhonecallEndReason) {
    const db = getDatabase();
    const otherPhonecallRef = push(dbRef(db, "phonecalls/" + Phonecalls.getOtherUid(auth, otherPhonecall)));
    const now = Date.now();
    const phonecall = {
      ...otherPhonecall,
      type: PhonecallType.END,
      time: now,
      ended: now,
      payload: {
        reason: reason,
      } as PhonecallEndPayload,
    } as Phonecall;
    await set(otherPhonecallRef, phonecall);
  }

  private attachTrack(track: LocalTrack | RemoteTrack, participant: LocalParticipant | RemoteParticipant) {
    this.roomListener.onAttachTrack(this.room, track, participant);
  }

  private detachTrack(track: LocalTrack | RemoteTrack, participant: LocalParticipant | RemoteParticipant) {
    this.roomListener.onDetachTrack(this.room, track, participant);
  }

  private participantConnected(participant: LocalParticipant | RemoteParticipant) {
    // Handle the TrackPublications already published by the Participant.
    participant.tracks.forEach(publication => {
      this.trackPublished(publication, participant);
    });

    // Handle theTrackPublications that will be published by the Participant later.
    participant.on('trackPublished', publication => {
      this.trackPublished(publication, participant);
    });
  }

  private participantDisconnected(participant: LocalParticipant | RemoteParticipant) {
  }

  private trackPublished(publication: LocalTrackPublication | RemoteTrackPublication, participant: LocalParticipant | RemoteParticipant) {
    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (publication.track) {
      this.attachTrack(publication.track, participant);
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    publication.on('subscribed', track => {
      this.attachTrack(track, participant);
    });

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    publication.on('unsubscribed', track => {
      this.detachTrack(track, participant);
    });
  }

  private room: Room | null;

  private async joinRoom(token: string, connectOptions: any, listener: RoomListener) {
    this.roomListener = listener;
    // Comment the next two lines to disable verbose logging.
    const logger = Logger.getLogger('twilio-video');
    logger.setLevel('error');

    // Join to the Room with the given AccessToken and ConnectOptions.
    const room = await connect(token, connectOptions);
    this.room = room;
    this.roomListener.onConnected(this.room);

    // Save the LocalVideoTrack.
    const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;

    // Make the Room available in the JavaScript console for debugging.
    // window.room = room;

    // Handle the LocalParticipant's media.
    this.participantConnected(room.localParticipant);

    // Subscribe to the media published by RemoteParticipants already in the Room.
    room.participants.forEach(participant => {
      this.participantConnected(participant);
    });

    // Subscribe to the media published by RemoteParticipants joining the Room later.
    room.on('participantConnected', participant => {
      this.participantConnected(participant);
    });

    // Handle a disconnected RemoteParticipant.
    room.on('participantDisconnected', participant => {
      this.participantDisconnected(participant);
    });

    room.once('disconnected', (room, error) => {
      // Stop the LocalVideoTrack.
      localVideoTrack.stop();

      // Handle the disconnected LocalParticipant.
      this.participantDisconnected(room.localParticipant);

      // Handle the disconnected RemoteParticipants.
      room.participants.forEach(participant => {
        this.participantDisconnected(participant);
      });

      this.roomListener.onDisconnected(this.room);
    });
  }

  getOtherParticipantCount(): number {
    return this.room?.participants.size || 0;
  }

  static getOtherUid(auth: Auth, phonecall: Phonecall) {
    return auth.currentUser.uid === phonecall.from ? phonecall.to : phonecall.from;
  }
}

export class ButtonState {

  constructor(readonly disabled?: boolean, readonly selected?: boolean) {
  }

  toggleSelected(): ButtonState {
    return new ButtonState(this.disabled, !this.selected);
  }
}

export class Action {

  tag?: any;
  destructive?: boolean;

  isSelected?: () => boolean;

  constructor(readonly text: string, readonly onClick?: (event: any, ...args: any[]) => void, readonly iconType?: typeof SvgIcon, readonly iconUrl?: string, readonly disabled?: boolean) {
  }

  makeDestructive(): Action {
    this.destructive = true;
    return this;
  }
}

export type EmptyConfig = {
  iconType: typeof SvgIcon,
  title: string,
  text?: string,
  action?: Action,
  altAction?: Action,
}
