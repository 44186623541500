import React from "react";
import {Auth} from "@firebase/auth";
import {Box, Button, Typography} from "@mui/material";
import {PD_MD, PD_SM, PD_XSM} from "./dimens";
import {MailOutlineOutlined, PersonOutlined} from "@mui/icons-material";
import {BASE_STATE} from "./BaseFragment";
import {ConnectionsFragment} from "./ConnectionsFragment";
import {InvitationsFragment} from "./InvitationsFragment";
import {
  BaseFullscreenDialogFragment,
  BaseFullscreenDialogFragmentProps,
  BaseFullscreenDialogFragmentState
} from "./BaseFullscreenDialogFragment";

export type PhonecallsFragmentProps = BaseFullscreenDialogFragmentProps & {
  auth: Auth,
}

type PhonecallsFragmentState = BaseFullscreenDialogFragmentState & {
  filter: ContactsFilter,
}

enum ContactsFilter {
  CONNECTIONS = "connections",
  INVITATIONS = "invitations",
}

export class PhonecallsFragment extends BaseFullscreenDialogFragment<PhonecallsFragmentProps, PhonecallsFragmentState> {

  constructor(props: PhonecallsFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
      filter: ContactsFilter.CONNECTIONS,
    };
  }

  protected renderFullscreenDialogContent(): React.ReactElement | null {
    let rendered;
    switch (this.state.filter) {
      case ContactsFilter.CONNECTIONS:
        rendered = <ConnectionsFragment auth={this.props.auth}/>;
        break;
      case ContactsFilter.INVITATIONS:
        rendered = <InvitationsFragment auth={this.props.auth}/>;
        break;
    }
    return <>
      <Box style={{display: "flex", gap: PD_MD, margin: "auto"}}>
        <Button
          color="secondary"
          variant={this.state.filter === ContactsFilter.CONNECTIONS ? "contained" : "outlined"}
          onClick={() => this.setState({filter: ContactsFilter.CONNECTIONS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <PersonOutlined/>
            <Typography variant="h6">Connections</Typography>
          </Box>
        </Button>
        <Button
          color="secondary"
          variant={this.state.filter === ContactsFilter.INVITATIONS ? "contained" : "outlined"}
          onClick={() => this.setState({filter: ContactsFilter.INVITATIONS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <MailOutlineOutlined/>
            <Typography variant="h6">Invitations</Typography>
          </Box>
        </Button>
      </Box>
      {rendered}
    </>;
  }
}
