import {BaseFragment} from "./BaseFragment";
import {Box} from "@mui/material";
import {StyledPdfView} from "./Components";
import {EmptyConfig} from "./types";
import {CampaignOutlined, PersonOutlined} from "@mui/icons-material";
import {Auth} from "@firebase/auth";

export type PlaceholderFragmentProps = {
  auth: Auth,
}

export class PlaceholderFragment extends BaseFragment<PlaceholderFragmentProps> {

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: CampaignOutlined,
      title: "Coming soon",
      text: "This section is under construction. Please check back again later.",
    };
  }

  render(): JSX.Element {
    return this.renderEmpty();
  }
}
