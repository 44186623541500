import {Box, ButtonBase, Card, CircularProgress, Fab, Tooltip, Typography} from "@mui/material";
import {Auth} from "@firebase/auth";
import {BaseFullscreenDialogFragmentProps, BaseFullscreenDialogFragmentState} from "./BaseFullscreenDialogFragment";
import {black, colorGreen, colorRed, mediumGray, nightGray, translucentBlack, white} from "./colors";
import {
  CallEndOutlined,
  CallOutlined,
  CloseOutlined,
  KeyboardArrowDownOutlined,
  MicOffOutlined,
  VideocamOffOutlined
} from "@mui/icons-material";
import {Permission, PermissionsFragment} from "./PermissionsFragment";
import {
  Action,
  ButtonState,
  Phonecall,
  PHONECALL_END_REASON_TEXT_FROM_ME,
  PHONECALL_END_REASON_TEXT_FROM_OTHER,
  PhonecallEndPayload,
  PhonecallEndReason,
  Phonecalls,
  PhonecallType,
  RoomListener,
  User,
  UserCache,
  UserDisplayName,
  UserProfilePhoto
} from "./types";
import {DW_LG, PD_LG, PD_MD, PD_SM, PD_XSM, SZ_MD} from "./dimens";
import React, {Component, ReactElement} from "react";
import {
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalVideoTrack,
  RemoteParticipant,
  RemoteTrack,
  Room
} from "twilio-video";
import {AppRefs} from "./App";

export interface PhonecallFragmentListener {
  onMinimizedStateChanged(minimized: boolean);
}

export type PhonecallFragmentProps = BaseFullscreenDialogFragmentProps & {
  auth: Auth,
  appRefs: AppRefs,
  listener: PhonecallFragmentListener,
}

enum PermissionsState {
  GRANTED,
  DENIED,
  NOT_GRANTED,
}

type PhonecallFragmentState = BaseFullscreenDialogFragmentState & {
  minimized?: boolean,
  phonecall: Phonecall | null,
  permissionsState?: PermissionsState,
  otherUser?: User,
  cameraOff?: ButtonState,
  muteOn?: ButtonState,
}

export class PhonecallFragment extends Component<PhonecallFragmentProps, PhonecallFragmentState> implements RoomListener {

  private readonly phonecalls = Phonecalls.getInstance();

  constructor(props: PhonecallFragmentProps, context: any) {
    super(props, context);
    this.state = {
      phonecall: null,
      permissionsState: PermissionsState.NOT_GRANTED,
    };
  }

  isMinimized(): boolean {
    return this.state.minimized;
  }

  minimize() {
    this.setState({
      minimized: true,
    });
  }

  maximize() {
    this.setState({
      minimized: false
    });
  }

  hasPhonecall(): boolean {
    return Boolean(this.state.phonecall);
  }

  onConnected(room: Room) {
  }

  onAttachTrack(room: Room, track: LocalTrack | RemoteTrack | null, participant: LocalParticipant | RemoteParticipant) {
    let renderer: HTMLMediaElement;
    switch (track?.kind) {
      case "audio":
        if (track instanceof LocalAudioTrack) {
          if ((renderer = this.props.appRefs.localAudioRenderer.current)) {
            track.attach(renderer);
          }
        } else {
          if ((renderer = this.props.appRefs.remoteAudioRenderer.current)) {
            track.attach(renderer);
          }
        }
        break;
      case "video":
        if (track instanceof LocalVideoTrack) {
          if ((renderer = this.props.appRefs.localVideoRenderer.current)) {
            track.attach(renderer);
          }
        } else {
          if ((renderer = this.props.appRefs.remoteVideoRenderer.current)) {
            track.attach(renderer);
          }
        }
        break;
    }
  }

  onDetachTrack(room: Room, track: LocalTrack | RemoteTrack | null, participant: LocalParticipant | RemoteParticipant) {
    let renderer: HTMLMediaElement;
    switch (track?.kind) {
      case "audio":
        if (track instanceof LocalAudioTrack) {
          if ((renderer = this.props.appRefs.localAudioRenderer.current)) {
            track.detach(renderer);
            renderer.srcObject = null;
          }
        } else {
          if ((renderer = this.props.appRefs.remoteAudioRenderer.current)) {
            track.detach(renderer);
            renderer.srcObject = null;
          }
        }
        break;
      case "video":
        if (track instanceof LocalVideoTrack) {
          if ((renderer = this.props.appRefs.localVideoRenderer.current)) {
            track.detach(renderer);
            renderer.srcObject = null;
          }
        } else {
          if ((renderer = this.props.appRefs.remoteVideoRenderer.current)) {
            track.detach(renderer);
            renderer.srcObject = null;
          }
        }
        break;
    }
  }

  onDisconnected(room: Room) {
  }


  getPhonecall(): Phonecall | null {
    return this.state.phonecall;
  }

  setPhonecall(phonecall: Phonecall | null) {
    this.setState({
      phonecall: phonecall,
    });
    if (!phonecall) {
      this.setState({
        minimized: false,
      })
    }
    if (phonecall?.type === PhonecallType.CREATE) {
      this.setState({
        otherUser: null,
      });
      UserCache.getInstance()
        .getUser(Phonecalls.getOtherUid(this.props.auth, phonecall))
        .then(user => this.setState({
          otherUser: user,
        }));
    }
  }

  componentDidUpdate(prevProps: Readonly<PhonecallFragmentProps>, prevState: Readonly<PhonecallFragmentState>, snapshot?: any) {
    if (prevState.permissionsState !== this.state.permissionsState || prevState.phonecall !== this.state.phonecall) {
      if (this.state.permissionsState === PermissionsState.GRANTED && this.state.phonecall?.type === PhonecallType.CREATE) {
        const isMe = this.state.phonecall.from === this.props.auth.currentUser.uid;
        if (isMe) {
          this.phonecalls.ringCurrentPhonecall(this.props.auth, this);
        }
      }
    }
    if (prevState.minimized !== this.state.minimized) {
      this.props.listener.onMinimizedStateChanged(this.state.minimized);
    }
  }

  render(): JSX.Element {
    if (!this.state.phonecall) {
      return null;
    }
    return !this.state.minimized ? <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          position: "absolute",
          background: black,
          zIndex: 10000,
        }}>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: PD_MD,
          }}>
          {this.renderParticipant(true, this.props.appRefs.localAudioElement, this.props.appRefs.localVideoElement)}
          {this.renderParticipant(false, this.props.appRefs.remoteAudioElement, this.props.appRefs.remoteVideoElement)}
        </Box>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            background: this.phonecalls.getOtherParticipantCount() ? null : translucentBlack,
          }}>
          {this.state.permissionsState === PermissionsState.GRANTED
            ? this.renderContent()
            : (this.state.permissionsState === PermissionsState.NOT_GRANTED || this.state.permissionsState === PermissionsState.DENIED ?
              <PermissionsFragment
                permissions={[Permission.CAMERA_AND_MICROPHONE]}
                promptTitle="Permissions"
                promptText="Before we can start a call, we need some permissions from you."
                deniedTitle="Video chat"
                deniedText="We cannot start this call because you denied permissions."
                onGranted={() => {
                  this.setState({permissionsState: PermissionsState.GRANTED})
                }}
                onDenied={() => {
                  this.setState({permissionsState: PermissionsState.DENIED});
                  this.phonecalls.endCurrentPhonecall(this.props.auth, PhonecallEndReason.NO_PERMISSIONS);
                }}
                onDismiss={() => this.phonecalls.clearCurrentPhonecall()}
              />
              : null)
          }
        </Box>
      </Box>
      : <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          position: "fixed",
          pointerEvents: "none",
          zIndex: 10000,
        }}>
        <Box
          onClick={() => this.maximize()}
          style={{
            pointerEvents: "auto",
            width: 180,
            height: 360,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: PD_MD,
            right: PD_MD,
            gap: PD_XSM,
          }}>
          {this.renderParticipant(true, this.props.appRefs.localAudioElement, this.props.appRefs.localVideoElement)}
          {this.renderParticipant(false, this.props.appRefs.remoteAudioElement, this.props.appRefs.remoteVideoElement)}
        </Box>
      </Box>;
  }

  private renderParticipant(isLocal: boolean, audioElement: ReactElement, videoElement: ReactElement) {
    return <Card style={{
      background: nightGray,
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 0,
      aspectRatio: 1,
      display: this.state.phonecall?.type !== PhonecallType.END && (isLocal || this.phonecalls.getOtherParticipantCount()) ? "block" : "none",
    }}>
      {audioElement}
      {videoElement}
    </Card>;
  }

  private renderContent() {
    switch (this.state.phonecall?.type) {
      case PhonecallType.CREATE:
        return this.renderCreateCall();
      case PhonecallType.CONNECT:
        return this.renderConnectCall();
      case PhonecallType.START:
        return this.renderStartCall();
      case PhonecallType.END:
        return this.renderEndCall();
    }
    return null;
  }

  private renderCreateCall() {
    return this.renderOverlay(this.renderCreateCallContent());
  }

  private renderConnectCall() {
    return this.renderOverlay(this.renderConnectCallContent());
  }

  private renderStartCall() {
    return this.renderOverlay(this.renderStartCallContent(), "flex-end");
  }

  private renderEndCall() {
    return this.renderOverlay(this.renderEndCallContent());
  }

  private renderOverlay(content: any, justifyContent?: string) {
    return <Box style={{
      position: "absolute",
      left: PD_MD,
      right: PD_MD,
      top: PD_MD,
      bottom: PD_MD,
      margin: "auto",
      maxWidth: DW_LG,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: PD_LG,
    }} justifyContent={justifyContent || "center"}>
      {content}
    </Box>;
  }

  private renderCallButtons() {
    const isMe = this.state.phonecall.from === this.props.auth.currentUser.uid;
    const starting = this.state.phonecall.type === PhonecallType.CONNECT || this.state.phonecall.type === PhonecallType.START;
    return <>
      {this.renderCallButton(new Action("Disconnect", event => this.onDisconnect(), CallEndOutlined), colorRed, "white")}
      {this.renderCallButton(new Action("Camera off", event => this.onToggleCameraOff(), VideocamOffOutlined), this.state.cameraOff?.selected ? white : mediumGray)}
      {this.renderCallButton(new Action("Mute", event => this.onToggleMuteOn(), MicOffOutlined), this.state.muteOn?.selected ? "white" : mediumGray)}
      {!isMe && !starting ?
        this.renderCallButton(new Action("Connect", event => this.onConnect(), CallOutlined), colorGreen, "white")
        : null}
    </>;
  }

  private renderCreateCallContent() {
    return <>
      <Card style={{width: 240, aspectRatio: 1, background: white}}>
        <img src={UserProfilePhoto(this.state.otherUser)} style={{width: "100%", height: "100%"}}/>
      </Card>
      <Box style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: white,
        gap: PD_SM,
      }}>
        <Typography variant="h4">{UserDisplayName(this.state.otherUser)}</Typography>
        <Typography>Calling...</Typography>
      </Box>
      <Box style={{
        display: "flex",
        gap: PD_LG,
      }}>
        {this.renderCallButtons()}
      </Box>
    </>;
  }

  private renderConnectCallContent() {
    return <>
      <Box style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: white,
        gap: PD_SM,
      }}>
        <CircularProgress />
        <Typography variant="h4">{UserDisplayName(this.state.otherUser)}</Typography>
        <Typography>Connecting...</Typography>
      </Box>
      <Box style={{
        display: "flex",
        gap: PD_LG,
      }}>
        {this.renderCallButtons()}
      </Box>
    </>;
  }

  private renderStartCallContent() {
    return <>
      <Box style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: white,
        gap: PD_SM,
      }}>
        <Typography variant="h4">{UserDisplayName(this.state.otherUser)}</Typography>
        <Fab variant="extended"
             color="secondary"
             style={{
               padding: PD_MD,
             }}
             onClick={() => this.minimize()}>
          <KeyboardArrowDownOutlined sx={{mr: 1}}/>
          Play together
        </Fab>
      </Box>
      <Box style={{
        display: "flex",
        gap: PD_LG,
      }}>
        {this.renderCallButtons()}
      </Box>
    </>;
  }

  private renderEndCallContent() {
    const isMe = this.state.phonecall.from === this.props.auth.currentUser.uid;
    const reason = (this.state.phonecall.payload as PhonecallEndPayload).reason;
    return <>
      <Box style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: white,
        gap: PD_SM,
      }}>
        <Typography variant="h4">{UserDisplayName(this.state.otherUser)}</Typography>
        <Typography>{isMe ? PHONECALL_END_REASON_TEXT_FROM_ME.get(reason) : PHONECALL_END_REASON_TEXT_FROM_OTHER.get(reason)}</Typography>
      </Box>
      <Box style={{
        display: "flex",
        gap: PD_LG,
      }}>
        {this.renderEndCallButtons(reason)}
      </Box>
    </>;
  }

  private renderEndCallButtons(reason: PhonecallEndReason) {
    const isMe = this.state.phonecall.from === this.props.auth.currentUser.uid;
    switch (reason) {
      case PhonecallEndReason.NO_ANSWER:
      case PhonecallEndReason.TIME_OUT:
      case PhonecallEndReason.BUSY:
      case PhonecallEndReason.NAVIGATED_AWAY:
        return <>
          {this.renderCallButton(new Action("Dismiss", event => this.onDismiss(), CloseOutlined), white, black)}
          {this.renderCallButton(new Action(isMe ? "Call again" : "Call back", event => this.onCallAgain(), CallOutlined), colorGreen, "white")}
        </>;
    }
    return null;
  }

  private onConnect() {
    this.phonecalls.connectAndStartCurrentPhonecall(this.props.auth, this);
  }

  private onDisconnect() {
    this.phonecalls.endCurrentPhonecall(this.props.auth, PhonecallEndReason.HANG_UP);
  }

  private onToggleCameraOff() {

  }

  private onToggleMuteOn() {

  }

  private onDismiss() {
    this.phonecalls.clearCurrentPhonecall();
  }

  private onCallAgain() {
    const phonecall = this.state.phonecall;
    this.phonecalls.clearCurrentPhonecall();
    this.phonecalls.createPhonecall(this.props.auth, Phonecalls.getOtherUid(this.props.auth, phonecall));
  }

  private renderCallButton(action: Action, background: string, foreground: string = "black") {
    const IconType = action.iconType;
    return <Tooltip arrow title={action.text}>
      <Card style={{background: background}}>
        <ButtonBase style={{width: SZ_MD, height: SZ_MD}}
                    onClick={(event) => action.onClick(event)}>
          <IconType style={{fontSize: 36, color: foreground}}/>
        </ButtonBase>
      </Card>
    </Tooltip>;
  }
}
