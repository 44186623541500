import React from "react";
import {Box, Button, Fab, TextField, Typography} from "@mui/material";
import {DW_SM, PD_MD, PD_SM, PD_XSM} from "./dimens";
import {DeleteOutlined, PersonAddOutlined, PersonOutlined, VideoCallRounded} from "@mui/icons-material";
import {Action, Connections, Contact, EmptyConfig, Invitations, Phonecalls, UserCache} from "./types";
import App from "./App";
import {
  BaseContactsFragment,
  BaseContactsFragmentProps,
  BaseContactsFragmentState,
  ContactItem
} from "./BaseContactsFragment";
import {Permission, PermissionsFragment} from "./PermissionsFragment";

export type ConnectionsFragmentProps = BaseContactsFragmentProps & {}

type ConnectionsFragmentState = BaseContactsFragmentState & {}

type CreateInvite = {
  email: string,
}

export class ConnectionsFragment extends BaseContactsFragment<ConnectionsFragmentProps, ConnectionsFragmentState> {

  private readonly connections = Connections.getInstance();
  private readonly invitations = Invitations.getInstance();

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: PersonOutlined,
      title: "No connections found",
      text: "Use the invite button to invite people to Playtime.",
    };
  }

  protected async fetchOnMount(): Promise<void> {
    this.setState({
      items: await Promise.all(this.connections.getConnections().map(contact => {
        async function getContactItem(contact: Contact) {
          const user = await UserCache.getInstance().getUser(contact.uid);
          return {
            contact: contact,
            user: user,
          } as ContactItem;
        }

        return getContactItem(contact);
      }))
    })
  }

  protected renderContainerContent(): React.ReactElement | null {
    let items = this.state.items;
    return <>
      {this.renderItems(items)}
      <Fab variant="extended"
           color="secondary"
           style={{
             position: "absolute",
             right: 24,
             bottom: 24,
             padding: 24
           }}
           onClick={() => {
             this.createInvite = {
               email: "",
             };
             App.CONTEXT.showDialog(null, props => this.renderCreateInviteDialog());
           }}>
        <PersonAddOutlined sx={{mr: 1}}/>
        Invite
      </Fab>
    </>;
  }

  protected renderItemAccessory(item: ContactItem): React.ReactElement | null {
    return <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
      <Button
        variant="contained"
        onMouseDown={event => event.stopPropagation()}
        onClick={() => {
          App.CONTEXT.hideAllDialogs();
          Phonecalls.getInstance().createPhonecall(this.props.auth, item.user.uid);
        }}
      >
        <Box style={{display: "flex", gap: PD_XSM}}>
          <VideoCallRounded/>
          <Typography>Call</Typography>
        </Box>
      </Button>
      {this.renderAccessoryMore([
        new Action("Delete", () => {
          App.CONTEXT.showAlert("Delete connection", "Are you sure want to delete this connection? This action cannot be undone.",
            [new Action("Cancel"), new Action("Delete", () => {
              this.connections.deleteConnection(this.props.auth, item.contact)
                .then((value) => {
                  App.CONTEXT.showToast("Deleted.");
                  this.reload();
                })
                .catch(reason => App.CONTEXT.showToast("Oops, something went wrong. Please try again later."));
            }).makeDestructive()]);
        }, DeleteOutlined),
      ])}
    </Box>;
  }

  private createInvite: CreateInvite | null;

  private renderCreateInviteDialog() {
    return <Box style={{display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD, minWidth: DW_SM}}>
      <Typography variant="h5">Invite to Playtime</Typography>
      <TextField autoFocus placeholder="Email" onChange={event => this.createInvite.email = event.target.value}/>
      <Button variant="contained" onClick={() => {
        App.CONTEXT.hideDialog();
        this.invitations.sendInvite(this.props.auth, this.createInvite.email)
          .then((value) => App.CONTEXT.showToast("Invitation sent."))
          .catch(reason => App.CONTEXT.showToast("Oops, something went wrong. Please try again later."));
      }}><Typography>Send invitation</Typography></Button>
    </Box>;
  }
}
