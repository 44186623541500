
export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: string) {
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({name: permissionName});
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export async function isPermissionGranted(name: string) {
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({name: permissionName});
      return result.state === 'granted';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

// export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
//   width: 1280,
//   height: 720,
//   frameRate: 24,
// };
//
// // These are used to store the selected media devices in localStorage
// export const SELECTED_AUDIO_INPUT_KEY = 'selectedAudioInput';
// export const SELECTED_AUDIO_OUTPUT_KEY = 'selectedAudioOutput';
// export const SELECTED_VIDEO_INPUT_KEY = 'selectedVideoInput';
//
// export class LocalTracks {
//
//   private audioTrack: LocalAudioTrack;
//   private videoTrack: LocalVideoTrack;
//   private isAcquiringLocalTracks: boolean;
//
//   private static instance;
//
//   static getInstance() {
//     if (!this.instance) {
//       this.instance = new LocalTracks();
//     }
//     return this.instance;
//   }
//
//   private constructor() {
//   }
//
//   async getLocalVideo() {
//     const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
//
//     const {videoInputDevices} = await getDeviceInfo();
//
//     const hasSelectedVideoDevice = videoInputDevices.some(
//       device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
//     );
//
//     const options: CreateLocalTrackOptions = {
//       ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
//       name: `camera-${Date.now()}`,
//       ...(hasSelectedVideoDevice && {deviceId: {exact: selectedVideoDeviceId!}}),
//     };
//
//     return Video.createLocalVideoTrack(options).then(newTrack => {
//       this.videoTrack = newTrack;
//       return newTrack;
//     });
//   }
//
//   async removeLocalAudioTrack() {
//     if (this.audioTrack) {
//       this.audioTrack.stop();
//       this.audioTrack = undefined;
//     }
//   }
//
//   async removeLocalVideoTrack() {
//     if (this.videoTrack) {
//       this.videoTrack.stop();
//       this.videoTrack = undefined;
//     }
//   }
//
//   async getAudioAndVideoTracks() {
//     const {audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices} = await getDeviceInfo();
//
//     if (!hasAudioInputDevices && !hasVideoInputDevices) {
//       return Promise.resolve();
//     }
//
//     if (this.isAcquiringLocalTracks || this.audioTrack || this.videoTrack) {
//       return Promise.resolve();
//     }
//
//     this.isAcquiringLocalTracks = true;
//
//     const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
//     const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
//
//     const hasSelectedAudioDevice = audioInputDevices.some(
//       device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
//     );
//     const hasSelectedVideoDevice = videoInputDevices.some(
//       device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
//     );
//
//     // In Chrome, it is possible to deny permissions to only audio or only video.
//     // If that has happened, then we don't want to attempt to acquire the device.
//     const isCameraPermissionDenied = await isPermissionDenied('camera');
//     const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
//
//     const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
//     const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;
//
//     const localTrackConstraints = {
//       video: shouldAcquireVideo && {
//         ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
//         name: `camera-${Date.now()}`,
//         ...(hasSelectedVideoDevice && {deviceId: {exact: selectedVideoDeviceId!}}),
//       },
//       audio: shouldAcquireAudio && {
//         ...(hasSelectedAudioDevice && {deviceId: {exact: selectedAudioDeviceId!}}),
//       },
//     };
//
//     return Video.createLocalTracks(localTrackConstraints)
//       .then(tracks => {
//         const newVideoTrack = tracks.find(track => track.kind === 'video') as LocalVideoTrack;
//         const newAudioTrack = tracks.find(track => track.kind === 'audio') as LocalAudioTrack;
//         if (newVideoTrack) {
//           this.videoTrack = newVideoTrack;
//           // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
//           // in cases where the user's video is disabled.
//           window.localStorage.setItem(
//             SELECTED_VIDEO_INPUT_KEY,
//             newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
//           );
//         }
//         if (newAudioTrack) {
//           this.audioTrack = newAudioTrack;
//         }
//
//         // These custom errors will be picked up by the MediaErrorSnackbar component.
//         if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
//           const error = new Error();
//           error.name = 'NotAllowedError';
//           throw error;
//         }
//
//         if (isCameraPermissionDenied) {
//           throw new Error('CameraPermissionsDenied');
//         }
//
//         if (isMicrophonePermissionDenied) {
//           throw new Error('MicrophonePermissionsDenied');
//         }
//       })
//       .finally(() => this.isAcquiringLocalTracks = false);
//   }
// }
//
// export class RoomConnection {
//
//   // private isConnecting: boolean;
//   // private room: Room;
//   //
//   // constructor(readonly localTracks: LocalTracks[]) {
//   // }
//   //
//   // connect(token: string) {
//   //   this.isConnecting = true;
//   //   return Video.connect(token, { ...optionsRef.current, tracks: this.localTracks }).then(
//   //     newRoom => {
//   //       this.room = newRoom;
//   //       VideoRoomMonitor.registerVideoRoom(newRoom);
//   //       const disconnect = () => newRoom.disconnect();
//   //
//   //       // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
//   //       // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
//   //       newRoom.setMaxListeners(16);
//   //
//   //       newRoom.once('disconnected', () => {
//   //         // Reset the room only after all other `disconnected` listeners have been called.
//   //         setTimeout(() => setRoom(null));
//   //         window.removeEventListener('beforeunload', disconnect);
//   //
//   //         if (isMobile) {
//   //           window.removeEventListener('pagehide', disconnect);
//   //         }
//   //       });
//   //
//   //       // @ts-ignore
//   //       window.twilioRoom = newRoom;
//   //
//   //       newRoom.localParticipant.videoTracks.forEach(publication =>
//   //         // All video tracks are published with 'low' priority because the video track
//   //         // that is displayed in the 'MainParticipant' component will have it's priority
//   //         // set to 'high' via track.setPriority()
//   //         publication.setPriority('low')
//   //       );
//   //
//   //       setIsConnecting(false);
//   //
//   //       // Add a listener to disconnect from the room when a user closes their browser
//   //       window.addEventListener('beforeunload', disconnect);
//   //
//   //       if (isMobile) {
//   //         // Add a listener to disconnect from the room when a mobile user closes their browser
//   //         window.addEventListener('pagehide', disconnect);
//   //       }
//   //     },
//   //     error => {
//   //       onError(error);
//   //       setIsConnecting(false);
//   //     }
//   //   );
//   // }
// }
