import {Box, Button, Rating, TextField, Typography} from "@mui/material";
import {DW_SM, PD_MD} from "./dimens";
import React from "react";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import {get, getDatabase, push, ref as dbRef, set} from "@firebase/database";
import App from "./App";
import {Auth} from "@firebase/auth";

export type AboutFragmentProps = {
  auth: Auth,
}

type AboutFragmentState = BaseFragmentState & {
  rating: number,
  feedbackText: string,
}

type Rating = {
  rating: number,
  time: number,
}

type Feedback = {
  text: string,
  time: number,
}

export class AboutFragment extends BaseFragment<AboutFragmentProps, AboutFragmentState> {
  constructor(props: AboutFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
      rating: 0,
      feedbackText: "",
    }
  }

  componentDidUpdate(prevProps: Readonly<AboutFragmentProps>, prevState: Readonly<AboutFragmentState>, snapshot?: any) {
    if (this.state.rating !== 0 && prevState.rating !== this.state.rating) {
      const db = getDatabase();
      set(dbRef(db, "ratings/" + this.props.auth.currentUser?.uid), {
        rating: this.state.rating,
        time: Date.now(),
      } as Rating)
        .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Rating failed."));
    }
  }

  protected async fetchOnMount(): Promise<void> {
    const db = getDatabase();
    const ratingRef = dbRef(db, "ratings/" + this.props.auth.currentUser?.uid);
    const result = await get(ratingRef);
    if (result.exists()) {
      const rating = result.val() as Rating;
      this.setState({
        rating: rating.rating,
      });
    }
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <Box style={{display: "flex", flexDirection: "column", maxWidth: DW_SM, gap: PD_MD, padding: PD_MD}}>
      <Box style={{width: 240, aspectRatio: 1, alignSelf: "center"}}>
        <img tabIndex={-1} src="/playtime_stamp.png" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
      </Box>
      <Typography style={{textAlign: "center"}}>How would you rate Playtime?</Typography>
      <Rating
        style={{alignSelf: "center"}}
        value={this.state.rating}
        onChange={(event, newValue) => {
          this.setState({rating: newValue});
        }}
      />
      <Typography style={{textAlign: "center"}}>If you have any feedback for us, please type it in the box below and
        press
        the Send button.</Typography>
      <TextField value={this.state.feedbackText} multiline minRows={3}
                 onChange={event => this.setState({feedbackText: event.target.value})}/>
      <Button variant="contained" onClick={() => {
        App.CONTEXT.hideDialog();
        const db = getDatabase();
        set(push(dbRef(db, "feedback/" + this.props.auth.currentUser?.uid)), {
          text: this.state.feedbackText,
          time: Date.now(),
        } as Feedback)
          .then(() => App.CONTEXT.showToast("Sent."))
          .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Feedback send failed."));
      }
      }>Send</Button>
    </Box>;
  }
}
