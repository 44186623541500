import {Box, ButtonBase, Card, IconButton, SvgIcon, Tab, Tabs} from "@mui/material";
import {Auth} from "@firebase/auth";
import {BORDER_RADIUS, CARD_SHADOW, PD_LG, PD_MD, PD_SM, SZ_HEADER, SZ_SM} from "./dimens";
import React from "react";
import {User, UserCache, UserProfilePhoto} from "./types";
import {UserFragment} from "./UserFragment";
import {
  AutoStoriesRounded,
  CallRounded,
  MusicNoteRounded,
  RocketLaunchRounded,
  SearchOutlined,
  VideogameAssetRounded
} from "@mui/icons-material";
import {BooksFragment} from "./BooksFragment";
import {colorGreen, gray, lightGray, nightGray, white} from "./colors";
import {PhonecallsFragment} from "./PhonecallsFragment";
import {SearchFragment} from "./SearchFragment";
import {PlaceholderFragment} from "./PlaceholderFragment";
import App, {
  DIALOG_FLAG_DISABLE_BACKDROP_CLICK,
  DIALOG_FLAG_SHOW_CLOSE,
  DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN
} from "./App";
import {AboutFragment} from "./AboutFragment";

export type MainProps = {
  auth: Auth,
}

export enum TabType {
  BOOKS = "books",
  GAMES = "games",
  MUSIC = "music",
  APPS = "apps",
}

type TabInfo = {
  type: TabType,
  text?: string,
  iconType?: typeof SvgIcon,
}

const TABS: TabInfo[] = [
  {
    type: TabType.BOOKS,
    text: "Books",
    iconType: AutoStoriesRounded,
  },
  {
    type: TabType.APPS,
    text: "Apps",
    iconType: RocketLaunchRounded,
  },
  {
    type: TabType.MUSIC,
    text: "Music",
    iconType: MusicNoteRounded,
  },
  {
    type: TabType.GAMES,
    text: "Games",
    iconType: VideogameAssetRounded,
  },
  // {
  //   type: TabType.ACTIVITIES,
  //   text: "Activities",
  //   iconType: EmojiEventsRounded,
  // },
  // {
  //   type: TabType.TOPICS,
  //   text: "Topics",
  //   iconType: EmojiObjectsRounded,
  // },
]
type MainState = {
  tabType: TabType,
  user?: User,
}

export class Main extends React.Component<MainProps, MainState> {

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {
      tabType: TabType.BOOKS,
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  private async fetchUser() {
    const user = await UserCache.getInstance().getUser(this.props.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  render() {
    let tabRendered = null;
    switch (this.state.tabType) {
      case TabType.BOOKS:
        tabRendered = <BooksFragment auth={this.props.auth}/>;
        break;
      case TabType.GAMES:
      case TabType.APPS:
      case TabType.MUSIC:
        tabRendered = <PlaceholderFragment auth={this.props.auth}/>;
        break;
    }
    return <>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_HEADER,
        alignItems: "flex-end",
        position: "relative",
        marginLeft: PD_LG,
        marginRight: PD_LG,
        gap: PD_MD,
      }}>
        <Tabs
          TabIndicatorProps={{
            style: {
              height: 0,
              maxWidth: 64,
              marginLeft: 16,
              borderRadius: 2,
            }
          }}
          value={this.state.tabType}
          style={{width: "100%", position: "absolute", alignSelf: "flex-end"}} centered
          onChange={(event, value) => this.setState({tabType: value})}>
          {TABS.map(tab => {
            const IconType = tab.iconType;
            return <Tab icon={<IconType style={{width: 32, height: 32}}/>} label={tab.text} value={tab.type} sx={{
              "&.Mui-selected": {
                backgroundColor: gray,
              }}}/>
          })}
        </Tabs>
        <ButtonBase
          onClick={() => App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
            <AboutFragment auth={this.props.auth}/>)}
        ><img src="/playtime_icon.png" style={{height: 64}}/></ButtonBase>
        <span style={{flexGrow: 1}}/>
        <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
          <IconButton
            style={{
              background: colorGreen,
              color: white
            }}
            onClick={() => {
              if (App.CONTEXT.hasPhonecall()) {
                App.CONTEXT.maximizePhonecallFragment();
              } else {
                App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () =>
                  <PhonecallsFragment title="Make a Call" auth={this.props.auth}/>);
              }
            }}>
            <CallRounded style={{
              margin: 8,
              width: 40,
              height: 40,
            }}/>
          </IconButton>
          <IconButton
            style={{
              background: gray,
              color: nightGray,
              width: SZ_SM,
              height: SZ_SM,
            }}
            onClick={() => {
              App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () =>
                <SearchFragment
                  title="Search" auth={this.props.auth}/>);
            }}>
            <SearchOutlined style={{
              width: "100%",
              height: "100%",
            }}/>
          </IconButton>
          <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
            <ButtonBase
              onClick={() => App.CONTEXT.showDialog(
                null,
                () => <UserFragment auth={this.props.auth}
                                    uid={this.state.user.uid}/>)}
            >
              <img src={UserProfilePhoto(this.state.user)} style={{width: "100%", height: "100%"}}/>
            </ButtonBase>
          </Card>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexGrow: 1,
          height: "100%",
          flexDirection: "column",
          gap: PD_MD,
          position: "relative",
        }}>
        {tabRendered}
      </Box>
    </>;
  }
}

