import React, {createRef, ReactElement, RefObject} from 'react';
import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  Snackbar,
  ThemeProvider,
  Typography,
  Zoom
} from "@mui/material";
import '../res/css/App.css';
import {getAuth, User} from '@firebase/auth';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {Login} from "./Login";
import {Main} from "./Main";
import {BORDER_RADIUS, CARD_SHADOW, DW_SM, PD_MD, PD_SM, PD_XSM, SZ_SM} from "./dimens";
import {black, colorPrimary, lightGray} from "./colors";
import {
  Action,
  BookSyncPayload,
  Connections,
  Invitations,
  Phonecall,
  PhonecallEndReason,
  Phonecalls,
  PhonecallsListener,
  Sync, SyncBook,
  SyncNone,
  SyncType
} from "./types";
import {getDatabase, push, ref as dbRef, remove, set} from "@firebase/database";
import {md5} from "./md5";
import {Close} from "@mui/icons-material";
import {PhonecallFragment, PhonecallFragmentListener} from "./PhonecallFragment";
import {FIREBASE_CONFIG} from "../consts";
import {BookFragment, BookFragmentListener} from "./BookFragment";

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

const theme = createTheme({
  // shadows: new Array<string>(25).fill("none") as Shadows,
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: CARD_SHADOW,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: PD_XSM,
          paddingBottom: PD_XSM,
          paddingLeft: PD_SM,
          paddingRight: PD_SM,
          textTransform: "none",
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: 96,
          fontWeight: "bold",
          borderRadius: BORDER_RADIUS,
        },
      }
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    h1: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h3: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h4: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h5: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h6: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    fontFamily: 'THICCCBOI, sans-serif',
    fontSize: 15,
  },
  palette: {
    primary: {
      main: colorPrimary,
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  }
});

export type DialogProps = {
  id?: string,
  flags?: number,
  args?: any[],
}

type ShowDialog = {
  flags: number,
  rendered: ReactElement,
  onClose?: () => void,
}

export type PopoverProps = {
  id?: string,
  flags?: number,
  args?: any[],
}

type ShowPopover = {
  anchorEl: HTMLElement,
  rendered: ReactElement,
  onClose?: () => void,
}

type ShowToast = {
  text: string,
  action?: Action,
  onClose?: () => void,
}

export const DIALOG_FLAG_DISABLE_BACKDROP_CLICK = 1 << 0;
export const DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN = 1 << 1;
export const DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE = 1 << 2;
export const DIALOG_FLAG_SHOW_CLOSE = 1 << 3;

type AppProps = {};

type AppState = {
  user?: User | null,
  ready?: boolean,
  sync?: Sync,
  hasPhonecall?: boolean,
  phonecallFragmentMinimized?: boolean,
  showToast?: ShowToast | null,
  showDialogs: ShowDialog[],
  showPopover?: ShowPopover | null,
}

function Splash() {
  return <Box style={{
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <Zoom in timeout={100} unmountOnExit><img src="/playtime_icon.png" style={{height: 128}}/></Zoom>
  </Box>;
}

export interface Context extends PhonecallsListener {
  showAlert(title: string, text: string, actions: Action[], onClose?: () => void): void;

  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): void;

  hideDialog(): void;

  hideAllDialogs(): void;

  showActions(anchorEl: HTMLElement, actions: Action[], onClose?: () => void): void;

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void;

  hidePopover();

  showToast(text: string): void,

  hasPhonecall(): boolean,

  maximizePhonecallFragment(): void,
}

export class AppRefs {

  constructor(
    readonly localAudioRenderer: RefObject<HTMLAudioElement>,
    readonly localAudioElement: ReactElement,
    readonly localVideoRenderer: RefObject<HTMLVideoElement>,
    readonly localVideoElement: ReactElement,
    readonly remoteAudioRenderer: RefObject<HTMLAudioElement>,
    readonly remoteAudioElement: ReactElement,
    readonly remoteVideoRenderer: RefObject<HTMLVideoElement>,
    readonly remoteVideoElement: ReactElement) {
  }
}

export default class App extends React.Component<AppProps, AppState> implements Context, PhonecallFragmentListener, BookFragmentListener {

  static CONTEXT: Context;

  static createAudioElement(ref: RefObject<HTMLAudioElement>, muted: boolean): ReactElement {
    return <audio ref={ref} muted={muted}/>;
  }

  static createVideoElement(ref: RefObject<HTMLVideoElement>): ReactElement {
    return <video ref={ref} autoPlay playsInline muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: BORDER_RADIUS,
                  }}></video>;
  }

  static createAppRefs() {
    const localAudioRenderer = createRef<HTMLAudioElement>();
    const localVideoRenderer = createRef<HTMLVideoElement>();
    const remoteAudioRenderer = createRef<HTMLAudioElement>();
    const remoteVideoRenderer = createRef<HTMLVideoElement>();
    return new AppRefs(
      localAudioRenderer,
      App.createAudioElement(localAudioRenderer, true),
      localVideoRenderer,
      App.createVideoElement(localVideoRenderer),
      remoteAudioRenderer,
      App.createAudioElement(remoteAudioRenderer, false),
      remoteVideoRenderer,
      App.createVideoElement(remoteVideoRenderer));
  }

  private readonly appRefs = App.createAppRefs();

  private readonly auth = getAuth();
  private readonly invitations = Invitations.getInstance();
  private readonly phonecalls = Phonecalls.getInstance();

  private appInit?: boolean;
  private readonly phonecallFragment = createRef<PhonecallFragment>();

  constructor(props: AppProps, context: any) {
    super(props, context);
    this.state = {
      showDialogs: [],
    };
    App.CONTEXT = this;
  }

  componentDidMount() {
    this.auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        if (this.appInit) {
          return;
        }
        this.appInit = true;
        this.onAppInit()
          .then(() => this.setState({
            user: user,
            ready: true,
          }));
      } else {
        this.appInit = false;
        this.setState({
          user: null,
          ready: true,
        });
      }
    });
    window.onbeforeunload = ev => {
      return this.state.hasPhonecall ? "Leave?" : null;
    };
    window.onunload = ev => {
      if (this.state.hasPhonecall) {
        this.phonecalls.endCurrentPhonecall(this.auth, PhonecallEndReason.NAVIGATED_AWAY);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
    if (this.state.sync?.id && prevState.sync?.id !== this.state.sync.id && this.state.sync.from === this.auth.currentUser?.uid) {
      this.phonecalls.setCurrentPhonecallSync(this.auth, this.state.sync);
    }
  }

  private async onAppInit(): Promise<void> {
    await this.invitations.loadInvitations(this.auth);
    const received = this.invitations.getReceived();
    const sent = this.invitations.getSent();
    // If I've both sent and received an invitation for an email address, add that as a contact.
    for (const inviteSent of sent) {
      for (const inviteReceived of received) {
        if (inviteSent.to === inviteReceived.email) {
          const db = getDatabase();
          // Add sender as connection.
          const newConnectionRef = push(dbRef(db, "connections/" + this.auth.currentUser.uid));
          await set(newConnectionRef, inviteReceived);
          // Remove invite I received.
          const receivedInviteRef = dbRef(db, "invitations/received/" + md5(this.auth.currentUser.email) + "/" + inviteReceived.id);
          await remove(receivedInviteRef);
          // Remove invite I sent.
          const sentInviteRef = dbRef(db, "invitations/sent/" + md5(this.auth.currentUser.email) + "/" + inviteSent.id);
          await remove(sentInviteRef);
        }
      }
    }
    await Connections.getInstance().loadConnections(this.auth);

    this.phonecalls.subscribe(this.auth, this);
  }

  onPhonecallChanged(currentPhonecall: Phonecall | null) {
    this.phonecallFragment.current?.setPhonecall(currentPhonecall);
    this.setState({
      hasPhonecall: Boolean(currentPhonecall),
    });
  }

  onSyncChanged(sync: Sync) {
    this.setState({
      sync: sync,
    });
  }

  onPageChanged(pdf: string, page: number) {
    this.setState({
      sync: SyncBook(this.auth, pdf, page),
    });
  }

  onMinimizedStateChanged(minimized: boolean) {
    this.setState({
      phonecallFragmentMinimized: minimized,
    });
  }

  showAlert(title: string, text: string, actions: Action[], onClose?: () => void): void {
    this.showDialog(null, () => <Box
      style={{width: DW_SM, display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD}}>
      <Typography variant="h6">{title}</Typography>
      <Typography>{text}</Typography>
      <Box style={{display: "flex", flexDirection: "row-reverse", gap: PD_SM}}>
        {actions.map(action => <Button
          variant="text"
          color={action.destructive ? "error" : "primary"}
          style={{textTransform: "uppercase"}}
          onClick={(event) => {
            this.hideDialog();
            action.onClick?.(event)
          }}>{action.text}</Button>)}
      </Box>
    </Box>);
  }


  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): void {
    if (!onClose) {
      onClose = () => App.CONTEXT.hideDialog();
    }
    let flags = props?.flags || 0;
    this.setState({
      showDialogs: [{
        flags: flags,
        rendered: render(props),
        onClose: onClose,
      } as ShowDialog,
        ...this.state.showDialogs]
    });
  }

  hideDialog(): void {
    this.setState({
      showDialogs: this.state.showDialogs.slice(1),
    });
  }

  hideAllDialogs(): void {
    this.setState({
      showDialogs: [],
    });
  }

  showActions(anchorEl: HTMLElement, actions: Action[], onClose?: () => void): void {
    this.showPopover(
      anchorEl,
      null,
      () => <List>
        {actions.map(action => {
            const IconType = action.iconType;
            return <ListItem>
              <ListItemButton
                onClick={(event) => {
                  App.CONTEXT.hidePopover();
                  action.onClick(event);
                }}>
                {IconType ? <ListItemIcon>
                    <IconType/>
                  </ListItemIcon>
                  : null}
                <Typography style={{marginLeft: -16}}>
                  {action.text}
                </Typography>
              </ListItemButton>
            </ListItem>;
          }
        )}
      </List>,
      onClose);
  }

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void {
    this.setState({
      showPopover: {
        anchorEl: anchorEl,
        rendered: render(props),
        onClose: onClose,
      } as ShowPopover,
    });
  }

  hidePopover() {
    this.setState({
      showPopover: null,
    });
  }

  showToast(text: string, action?: Action, onClose?: () => void): void {
    this.setState({
      showToast: {
        text: text,
        action: action,
        onClose: onClose,
      }
    });
  }

  hasPhonecall(): boolean {
    return this.phonecallFragment.current?.hasPhonecall();
  }

  maximizePhonecallFragment() {
    this.phonecallFragment.current?.maximize();
  }

  render() {
    let rendered;
    if (this.state.ready) {
      if (this.auth.currentUser) {
        rendered = <Main auth={this.auth}/>;
      } else {
        rendered = <Login auth={this.auth}/>;
      }
    } else {
      rendered = <Splash/>;
    }
    return <ThemeProvider theme={theme}>
      <Box style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: this.state.showDialogs?.length > 0 || (this.state.hasPhonecall && !this.state.phonecallFragmentMinimized) ? "hidden" : "inherit",
      }}>
        {Boolean(this.state.showToast) ?
          this.renderToast()
          : null}
        {this.state.showDialogs.reverse().map((showDialog, index) => (showDialog.flags & DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN) !== 0 ?
          this.renderFullscreenDialog(showDialog, index)
          : this.renderDialog(showDialog, index))}
        {Boolean(this.state.showPopover) ?
          this.renderPopover()
          : null}
        {rendered}
        {this.renderSync()}
        <PhonecallFragment ref={this.phonecallFragment} auth={this.auth} appRefs={this.appRefs} listener={this}/>
      </Box>
    </ThemeProvider>;
  }

  private renderSync(): ReactElement | null {
    switch (this.state.sync?.type) {
      case SyncType.BOOK:
        const bookSyncPayload = this.state.sync.payload as BookSyncPayload;
        return this.renderSyncContainerWithChild(
          <BookFragment
            pdf={bookSyncPayload.pdf}
            page={bookSyncPayload.page}
            listener={this}/>);
    }
    return null;
  }

  private renderSyncContainerWithChild(child: ReactElement): ReactElement {
    return <Box style={{width: "100%", height: "100%", position: "absolute", background: black}}>
      {this.renderCloseButton(() => this.onSyncChanged(SyncNone(this.auth)))}
      {child}
    </Box>;
  }

  private renderToast() {
    let actionRendered = null;
    if (this.state.showToast.action) {
      actionRendered =
        <Button variant="text" onClick={this.state.showToast.action.onClick}>{this.state.showToast.action.text}</Button>
    }
    return <Snackbar
      open
      autoHideDuration={3000}
      onClose={(event) => {
        this.state.showToast.onClose?.();
        this.setState({
          showToast: null,
        });
      }}
      message={this.state.showToast.text}
      action={actionRendered}
    />;
  }

  private renderFullscreenDialog(dialog: ShowDialog, index: number) {
    let immersive = (dialog.flags & DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE) !== 0;
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    return <Box style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1200 + index}}>
      <Backdrop style={{background: immersive ? black : null,}} open>
        <Box width="100%" height="100vh" style={{position: "relative"}}>
          {showClose ? this.renderCloseButton() : null}
          <Box style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
          }}>
            {dialog.rendered}
          </Box>
        </Box>
      </Backdrop>
    </Box>;
  }

  private renderDialog(dialog: ShowDialog, index: number) {
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    return <Dialog
      scroll="body"
      maxWidth="xl"
      open
      onClose={(event, reason) => {
        if (reason === "backdropClick" && (dialog.flags & DIALOG_FLAG_DISABLE_BACKDROP_CLICK)) {
          return;
        }
        this.hideDialog();
      }}>
      {showClose ? this.renderCloseButton() : null}
      {dialog.rendered}
    </Dialog>;
  }

  private renderCloseButton(onClose?: () => void) {
    if (!onClose) {
      onClose = () => this.hideDialog();
    }
    return <Box style={{
      display: "flex",
      left: PD_XSM,
      right: PD_XSM,
      top: PD_XSM,
      alignItems: "center",
      justifyContent: "flex-end",
      height: SZ_SM,
      position: "absolute",
      zIndex: 2000,
    }}>
      <IconButton style={{
        width: SZ_SM,
        height: SZ_SM,
        background: lightGray,
      }} onClick={onClose}>
        <Close style={{color: "black"}}/>
      </IconButton>
    </Box>;
  }

  private renderPopover() {
    return <Popover
      anchorEl={this.state.showPopover.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={(event) => {
        this.state.showPopover.onClose?.();
        this.setState({
          showPopover: null,
        });
      }}
      open>
      {this.state.showPopover.rendered}
    </Popover>
  }
}
