import {Box, Button, Card, IconButton, SvgIcon, Typography} from "@mui/material";
import {lightGray, white} from "./colors";
import {Close, VideoChatOutlined} from "@mui/icons-material";
import {DW_SM, PD_LG, PD_XSM, SZ_SM} from "./dimens";
import React from "react";
import {StyledListItem} from "./Components";
import {getDeviceInfo, isPermissionDenied, isPermissionGranted} from "./phonecall";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";

export enum Permission {
  CAMERA_AND_MICROPHONE,
}

type PermissionInfo = {
  title: string,
  text: string,
  icon: typeof SvgIcon,
  request: () => Promise<void>,
  names: string[],
}

const PERMISSION_INFOS = new Map<Permission, PermissionInfo>([
    [Permission.CAMERA_AND_MICROPHONE, {
      title: "Camera and Microphone",
      text: "Tap to grant permission",
      icon: VideoChatOutlined,
      request: () => new Promise((resolve, reject) => navigator.mediaDevices.getUserMedia({video: true, audio: true})
        .then((stream: MediaStream) => {
          stream.getTracks().forEach(track => track.stop());
          return getDeviceInfo();
        })
        .then(info => {
          if (info.videoInputDevices.every(device => !device.label) || info.audioInputDevices.every(device => !device.label)) {
            reject();
          } else {
            resolve()
          }
        })),
      names: ["camera", "microphone"],
    }],
  ]
);

export type PermissionsFragmentProps = {
  permissions: Permission[],
  promptTitle: string,
  promptText: string,
  deniedTitle: string,
  deniedText: string,
  onGranted?: () => void,
  onDenied?: () => void,
  onDismiss?: () => void,
}

type PermissionsFragmentState = BaseFragmentState & {
  promptPermissions?: Permission[],
  deniedPermissions?: Permission[],
}

export class PermissionsFragment extends BaseFragment<PermissionsFragmentProps, PermissionsFragmentState> {

  constructor(props: PermissionsFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    await this.checkPermissions();
  }

  componentDidUpdate(prevProps: Readonly<PermissionsFragmentProps>, prevState: Readonly<PermissionsFragmentState>, snapshot?: any) {
    if (prevState.deniedPermissions !== this.state.deniedPermissions && prevState.promptPermissions !== this.state.promptPermissions) {
      if (this.state.deniedPermissions) {
        if (!this.state.deniedPermissions.length) {
          if (this.state.promptPermissions) {
            if (!this.state.promptPermissions.length) {
              getDeviceInfo()
                .then(info => {
                  const videoDevices = info.videoInputDevices.filter(device => device.deviceId);
                  const videoDeviceInfo = videoDevices.find(device => device.deviceId === localStorage.getItem("videoDeviceId"));
                  if (!videoDeviceInfo) {
                    localStorage.setItem("videoDeviceId", videoDevices[0].deviceId);
                  }
                  const audioDevices = info.audioInputDevices.filter(device => device.deviceId);
                  const audioDeviceInfo = audioDevices.find(device => device.deviceId === localStorage.getItem("audioDeviceId"));
                  if (!audioDeviceInfo) {
                    localStorage.setItem("audioDeviceId", audioDevices[0].deviceId);
                  }
                }).then(() => this.props.onGranted?.());
            }
          }
        } else {
          this.props.onDenied?.();
        }
      }
    }
  }

  protected renderContainerContent(): React.ReactElement | null {
    return this.state.deniedPermissions.length
      ? this.renderDenied()
      : (this.state.promptPermissions.length
        ? this.renderPrompt()
        : null);
  }

  private renderDenied() {
    return <Box
      style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Card style={{
        flexGrow: 1,
        maxWidth: DW_SM,
        height: "50%",
        background: white,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: PD_LG,
        padding: PD_LG,
      }}>
        <Typography variant="h4" textAlign="center">Call ended</Typography>
        <Typography textAlign="center" style={{marginBottom: -24}}>{this.props.deniedText}</Typography>
        <span style={{flexGrow: 1}}/>
        <Button variant="contained" color="secondary"
                onClick={() => this.props.onDismiss?.()}><Typography>Okay</Typography></Button>
      </Card>
    </Box>;
  }

  private renderPrompt() {
    return <Box
      style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Card style={{
        flexGrow: 1,
        maxWidth: DW_SM,
        height: "50%",
        background: white,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: PD_LG,
        padding: PD_LG,
      }}>
        <Box style={{
          display: "flex",
          left: PD_XSM,
          right: PD_XSM,
          top: PD_XSM,
          alignItems: "center",
          justifyContent: "flex-end",
          height: SZ_SM,
          position: "absolute",
        }}>
          {this.props.onDenied ? <IconButton style={{
              width: SZ_SM,
              height: SZ_SM,
              background: lightGray,
            }} onClick={() => {
              this.props.onDenied();
              this.setState({
                deniedPermissions: this.state.promptPermissions,
              });
            }}>
              <Close style={{color: "black"}}/>
            </IconButton>
            : null}
        </Box>
        <Typography variant="h4" textAlign="center">Permissions</Typography>
        <Typography textAlign="center" style={{marginBottom: -24}}>{this.props.promptText}</Typography>
        {this.props.permissions.map(permission => {
          const info = PERMISSION_INFOS.get(permission);
          return <StyledListItem title={info.title} text={info.text} icon={info.icon}/>;
        })}
        <span style={{flexGrow: 1}}/>
        <Button variant="contained" color="secondary" onClick={() => {
          Promise.all(this.state.promptPermissions.map(permission => PERMISSION_INFOS.get(permission).request()))
            .then(() => this.checkPermissions())
            .catch(() => this.props.onDenied?.());
        }}><Typography>Grant permissions</Typography></Button>
      </Card>
    </Box>;
  }

  private async checkPermissions() {
    const deniedPermissions = [];
    const promptPermissions = [];
    for (const permission of this.props.permissions) {
      const info = PERMISSION_INFOS.get(permission);
      for (const name of info.names) {
        if (await isPermissionDenied(name)) {
          deniedPermissions.push(permission);
        }
        if (!(await isPermissionGranted(name))) {
          promptPermissions.push(permission);
        }
      }
    }
    this.setState({
      deniedPermissions: deniedPermissions,
      promptPermissions: promptPermissions,
    });
  }
}
