export const PD_XSM = 12;
export const PD_SM = 16;
export const PD_MD = 24;
export const PD_LG = 48;
export const PD_XLG = 72;
export const BORDER_RADIUS = 24;
export const BORDER_RADIUS_SMALL = 4;
// export const CARD_SHADOW = '0px 2px 32px 0px rgba(0, 0, 0, .1)';
export const CARD_SHADOW = '0px 8px 16px 0px rgba(0, 0, 0, .1)';

export const SZ_SM = 48;
export const SZ_MD = 72;
export const SZ_LG = 96;
export const SZ_XLG = 120;
export const SZ_HEADER = 128;
export const SZ_JUMBO = 240;
// export const CARD_SHADOW = '0px 24px 48px -16px rgba(0, 0, 0, .25)';
// export const CARD_SHADOW = '0px 24px 48px -16px rgba(0, 0, 0, .1)';

export const DW_XS = '320px';
export const DW_SM = '480px';
export const DW_MD = '648px';
export const DW_LG = '720px';
export const DW_XL = '960px';
export const DW_XXL = '1200px';
export const DW_JUMBO = '90vh';
export const DW = {
  'xs': DW_XS,
  'sm': DW_SM,
  'md': DW_MD,
  'lg': DW_LG,
  'xl': DW_XL,
  'xxl': DW_XXL,
  'jumbo': DW_JUMBO,
}
